import axios from 'axios'
import store from '@/store'

let axiosRetry = false
let requestCounter = 0
let refreshingToken = false
let awaitingRefresh = false
const queueCheckInterval = 25

const axiosInstance = axios.create()
console.log('baseURL', process.env)

axiosInstance.interceptors.request.use(
  async config => {
    requestCounter++
    // console.log('AXIOS REQUEST - access token expired:', store.getters['auth/accessTokenExpired'])
    await store.dispatch('global/httpClientLoading', true)
    return config
  },
  async error => {
    await store.dispatch('global/httpClientLoading', false)
    return Promise.reject(error)
  }
)
axiosInstance.interceptors.response.use(
  async response => {
    axiosRetry = false
    await store.dispatch('global/httpClientLoading', false)
    requestCounter--
    return response
  },
  async error => {
    console.log('ERROR', error)
    requestCounter--
    if (
      error.config &&
      (
        [
          '/api/v1/auth/token',
          '/api/v1/passwordless',
          '/api/v1/passwordless/start'
        ].includes(error.config.url)
      )
    ) {
      // Don't retry login
      return Promise.reject(error)
    }
    await store.dispatch('global/httpClientLoading', false)

    if (!error.response) {
      return Promise.reject(error)
    }

    const { status } = error.response
    const originalRequest = error.config
    if ((status === 401) && !axiosRetry) {
      console.log(status, axiosRetry)
      // Refresh access token and retry original request
      // axiosRetry = true
      let shouldRefresh = true
      if (awaitingRefresh) {
        shouldRefresh = false
      } else {
        shouldRefresh = true
        awaitingRefresh = true
      }
      return new Promise((resolve) => {
        const interval = setInterval(async () => {
          if (awaitingRefresh && !refreshingToken && shouldRefresh) {
            if (requestCounter === 0) {
              try {
                refreshingToken = true
                console.log('Trying to refresh access token')
                await store.dispatch('auth/refreshTokenGrant')
              } catch (e) {
                console.log('Failed to refresh token - Redirect to login')
                await store.dispatch('user/expireUser')
                window.location.href = '/login'
                return
                // return await router.push({ name: 'Login' })
              } finally {
                refreshingToken = false
                awaitingRefresh = false
              }
              clearInterval(interval)
              return resolve(axiosInstance(originalRequest))
            }
          } else {
            if (!awaitingRefresh) {
              clearInterval(interval)
              return resolve(axiosInstance(originalRequest))
            }
          }
        }, queueCheckInterval)
      })
    } else if (
      status !== 400 &&
      status !== 401 &&
      status !== 403 &&
      status !== 409 &&
      !axiosRetry
    ) {
      console.log('Retry original request', status, axiosRetry)
      // Retry original request not related to auth or bad data
      axiosRetry = true
      return axiosInstance(originalRequest)
    } else if (status === 403) {
      await store.dispatch('user/expireUser')
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
