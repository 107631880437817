import { Commit, Dispatch } from 'vuex'
import { applications } from '@/api'

interface State {
  applications: Array<any>
  applicationsCount: number
}

const state = {
  applications: [],
  applicationsCount: 0
} as State

const getters = {
  applications: (state: State): Array<any> => {
    return state.applications
  }
}

const actions = {
  async getApplications ({ commit }: { commit: Commit }): Promise<void> {
    const response = await applications.getApplications()
    if (response && response.data) {
      return response.data
    }
  },
  async getApplication ({ commit }: { commit: Commit }, id: string): Promise<void> {
    const { data } = await applications.getApplication(id)
    if (data) {
      return data
    }
  },
  async createApplication ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await applications.createApplication(d)
    return data
  },
  async updateApplication ({ commit, dispatch, rootGetters }: { commit: Commit, dispatch: Dispatch, rootGetters: any }, d: any): Promise<void> {
    const userId = d.id
    const { data } = await applications.updateApplication(userId, d.data)
    console.log('updateApplicationResponse', data)
    return data
  },
  async deleteApplication ({ commit }: { commit: Commit }, id: string): Promise<void> {
    const { data } = await applications.deleteApplication(id)
    return data
  },

  async getApplicationFiles ({ commit }: { commit: Commit }, id: string): Promise<void> {
    const { data } = await applications.getApplicationFiles(id)
    if (data) {
      return data
    }
  },
  async getApplicationFile ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await applications.getApplicationFile(d.id, d.fileId)
    if (data) {
      return data
    }
  },
  async getApplicationFileUploadUrl ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const appId = d.id
    const { data } = await applications.getApplicationFileUploadUrl(appId, d.data)
    if (data) {
      console.log('uploadApplicationFileResponse', data)
      return data
    }
  },
  async deleteApplicationFile ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await applications.deleteApplicationFile(d.id, d.fileId)
    return data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
