import httpClient from '@/api/httpClient'

export const getAuthenticatedUser = (): Promise<any> => {
  return httpClient.get('/api/v1/user')
}

export const getOnboardingChecklist = (): Promise<any> => {
  return httpClient.get('/api/v1/user/onboarding-checklist')
}

export const updateAuthenticatedUser = (data: any): Promise<any> => {
  return httpClient.put('/api/v1/user', data)
}

export const getProfilePicture = (): Promise<any> => {
  return httpClient.get('/api/v1/user/profile-picture')
}

export const deleteProfilePicture = (): Promise<any> => {
  return httpClient.delete('/api/v1/user/profile-picture')
}

export const getSignedUploadProfilePicture = (): Promise<any> => {
  return httpClient.get('/api/v1/user/profile-picture/signed-upload')
}

export const updateAuthenticatedUserPassword = (data: any): Promise<any> => {
  return httpClient.put('/api/v1/user/password', data)
}

export const createBooking = (data: any): Promise<any> => {
  return httpClient.post('/api/v1/user/bookings', data)
}

export const updateBooking = (bookingId: string, data: any): Promise<any> => {
  return httpClient.patch(`/api/v1/user/bookings/${bookingId}`, data)
}

export const createGuestBooking = (data: any): Promise<any> => {
  return httpClient.post('/api/v1/user/bookings/guest', data)
}

export const deleteBooking = (bookingId: string): Promise<any> => {
  return httpClient.delete(`/api/v1/user/bookings/${bookingId}`)
}

export const getBookingsForPlans = (startDate: string, endDate: string): Promise<any> => {
  return httpClient.get('/api/v1/user/bookings/plans', {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  })
}
