import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2269cdbb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert-message", {
      'is-notification': _ctx.isNotification,
      'state-info': _ctx.state === 'info',
      'state-warning': _ctx.state === 'warning',
      'state-error': _ctx.state === 'error',
      'state-success': _ctx.state === 'success',
    }])
  }, [
    (_ctx.isNotification && _ctx.message && _ctx.isBold)
      ? (_openBlock(), _createElementBlock("b", _hoisted_1, _toDisplayString(_ctx.message), 1))
      : (_ctx.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}