<template>
  <div
    v-if="routerLoading"
    class="d-flex position-fixed align-items-center justify-content-center w-100 h-100 spinner-container"
  >
    <div
      class="spinner-border"
      role="status"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapState } from 'vuex'

  export default defineComponent({
    name: 'RouterGuardLoader',
    computed: {
      ...mapState('global', {
        routerLoading: 'routerLoading'
      })
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/_palette.scss';

  .spinner-container {
    top: 0;
    left: 0;
    background-color: $body-background;
    z-index: 9999;
  }
</style>
