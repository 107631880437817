import httpClient from '@/api/httpClient'
import { GetUsers } from '@/interfaces'

export const getUsers = (params: GetUsers): Promise<any> => {
  return httpClient.get('/api/v1/users', {
    params
  })
}

export const getUser = (id: string): Promise<any> => {
  return httpClient.get(`/api/v1/users/${id}`)
}

export const createUser = (data: any): Promise<any> => {
  return httpClient.post('/api/v1/users', data)
}

export const updateUser = (id: string, data: any): Promise<any> => {
  return httpClient.put(`/api/v1/users/${id}`, data)
}

export const createUserApplicationAssignment = (id: string, appId: string): Promise<any> => {
  return httpClient.post(`/api/v1/users/${id}/applications/${appId}`)
}

export const deleteUser = (id: string): Promise<any> => {
  return httpClient.delete(`/api/v1/users/${id}`)
}

export const deleteUserApplicationAssignment = (id: string, appId: string): Promise<any> => {
  return httpClient.delete(`/api/v1/users/${id}/applications/${appId}`)
}
