<template>
  <div
    class="not-authenticated-form-container"
  >
    <div class="row justify-content-center">
      <div class="col col-12">
        <h2 class="form-title">
          Identity
        </h2>
        <p class="mb-4">User management platform login.</p>
        <AlertMessage
          v-if="$route.query.password_reset === 'true'"
          :is-notification="true"
          state="success"
          message="Password updated. Please login using your new password."
          class="mb-3"
        />
        <Form
          v-if="authStep === 1"
          v-slot="{ errors }"
          :validation-schema="schemaEmail"
          @submit="passwordlessStart"
        >
          <AlertMessage
            v-if="$route.query.oauth2_redirect_status === 'error'"
            :is-notification="true"
            state="error"
            message="Error authenticating with single sign-on."
            class="mb-3"
          />
          <AlertMessage
            v-if="errors.form"
            :is-notification="true"
            state="error"
            :message="errors.form"
            class="mb-3"
          />
          <div class="mb-1">
            <label for="email">
              Email
            </label>
            <Field
              id="email"
              ref="email"
              v-model="email"
              name="email"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
            />
            <div class="invalid-feedback">
              {{ errors.email }}
            </div>
          </div>
          <div class="row form-footer-row">
            <div class="col d-flex justify-content-start">
              <button
                id="submitUserLookup"
                type="submit"
                class="btn btn-primary"
              >
                <span
                  v-if="formSubmittingEmail"
                  class="spinner-border spinner-border-sm"
                />
                Send code
              </button>
            </div>
          </div>
        </Form>
        <Form
          v-if="authStep === 2"
          v-slot="{ errors }"
          :validation-schema="schemaToken"
          @submit="submitToken"
        >
          <AlertMessage
            v-if="errors.form"
            :is-notification="true"
            state="error"
            :message="errors.form"
            class="mb-3"
          />
          <div class="mb-1">
            <label
              for="token"
            >
              Enter your authentication code
            </label>
            <Field
              id="token"
              ref="token"
              name="token"
              type="text"
              maxlength="6"
              class="form-control"
              :class="{ 'is-invalid': errors.token }"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
            />
            <div class="invalid-feedback">
              {{ errors.token }}
            </div>
          </div>
          <div class="row form-footer-row">
            <div class="col d-flex justify-content-start align-items-center">
              <button
                id="submitLogin"
                type="submit"
                class="btn btn-primary"
              >
                <span
                  v-if="formSubmittingToken"
                  class="spinner-border spinner-border-sm"
                />
                Login
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, markRaw } from 'vue'
  import { mapActions } from 'vuex'
  import { Field, Form } from 'vee-validate'
  import * as yup from 'yup'
  import AlertMessage from '@/components/AlertMessage.vue'

  export default defineComponent({
    name: 'LoginView',
    components: {
      AlertMessage,
      Form,
      Field
    },
    data () {
      const schemaEmail = markRaw(yup.object({
        email: yup.string().required('Email is required').email('Email is invalid')
      }))
      const schemaToken = markRaw(yup.object({
        token: yup.string().required('Password is required')
      }))
      return {
        schemaEmail,
        schemaToken,
        formSubmittingEmail: false,
        formSubmittingToken: false,
        ssoSubmitting: false,
        teamsAppCheckComplete: false,
        teamsApp: false,
        teamsAppAuthenticationFrame: false,
        teamsAppAuthenticateError: false,
        fetchingAuthMethods: true,
        authMethods: {
          password: false,
          microsoft_oauth2: false,
          microsoft_oauth2_url: ''
        },
        email: '',
        authStep: 1,
        passwordInputType: 'password'
      }
    },
    mounted () {
      this.focusEmailField()
    },
    methods: {
      ...mapActions('auth', {
        passwordlessLogin: 'passwordlessLogin',
        passwordlessStartStore: 'passwordlessStart'
      }),
      changeEmail () {
        this.authStep = 1
      },
      focusEmailField () {
        this.$nextTick(() => {
          if (this.$refs.email) {
            const emailField: any = this.$refs.email
            emailField.$el.focus()
          }
        })
      },
      async passwordlessStart (values: Record<string, any>, { setFieldError }: {
        setFieldError?: (field: string, message: string | undefined) => void
      }) {
        const emailNotFoundErrorMessage = 'We do not recognise your email, please double check spelling.'
        if (!this.formSubmittingEmail) {
          try {
            this.formSubmittingEmail = true
            const response = await this.passwordlessStartStore(values)
            console.log(response)
            this.authStep = 2
            await this.$nextTick()
            if (this.$refs.token) {
              const tokenField: any = this.$refs.token
              tokenField.$el.focus()
            }
          } catch (e) {
            console.log(e.response)
            if (setFieldError) {
              if (e.response && e.response.data && e.response.data.message) {
                setFieldError('email', e.response.data.message)
              } else {
                setFieldError('email', emailNotFoundErrorMessage)
              }
            }
          } finally {
            this.formSubmittingEmail = false
          }
        }
      },
      async submitToken (values: Record<string, any>, { setFieldError }: {
        setFieldError: (field: string, message: string | undefined) => void
      }) {
        if (!this.formSubmittingToken) {
          try {
            this.formSubmittingToken = true
            await this.passwordlessLogin({
              ...values
            })
            this.$router.push({
              name: 'Applications'
            })
          } catch (e) {
            console.log(e.response)
            if (e.response && e.response.data && e.response.data.message) {
              setFieldError('token', e.response.data.message)
            } else {
              setFieldError('token', 'Incorrect code. Please try again.')
            }
            this.formSubmittingToken = false
          }
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/_variables.scss';
  @import '@/assets/sass/_functions.scss';
  @import '@/assets/sass/_palette.scss';
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  .spinner-container {
    top: 0;
    left: 0;
    background-color: $body-background;
    z-index: 9999;
  }

  .not-authenticated-form-container {
    .form-title {
      margin-bottom: toRem(8);
    }
  }

</style>
