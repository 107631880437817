<template>
  <RouterGuardLoader />
  <RouterView />
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import RouterGuardLoader from '@/components/RouterGuardLoader.vue'

  export default defineComponent({
    name: 'App',
    components: {
      RouterGuardLoader
    }
  })
</script>
