<template>
  <div
    class="alert-message"
    :class="{
      'is-notification': isNotification,
      'state-info': state === 'info',
      'state-warning': state === 'warning',
      'state-error': state === 'error',
      'state-success': state === 'success',
    }"
  >
    <b v-if="isNotification && message && isBold">
      {{ message }}
    </b>
    <p
      v-else-if="message"
      class="mb-0"
    >
      {{ message }}
    </p>
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'AlertMessage',
    props: {
      message: {
        required: false,
        type: String,
        default: ''
      },
      isNotification: {
        default: false,
        required: false,
        type: Boolean
      },
      isBold: {
        default: false,
        required: false,
        type: Boolean
      },
      state: {
        default: 'warning',
        required: false,
        type: String
      }
    },
    computed: {
      icon () {
        switch (this.state) {
          case 'info':
            return 'info'
          case 'error':
            return 'error'
          case 'success':
            return 'valid'
          default:
            return 'warning'
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/assets/sass/_palette.scss';
  @import '@/assets/sass/_functions.scss';

  .alert-message {
    position: relative;
    display: block;
    border-radius: toRem(8);
    padding: toRem(16);

    &.is-notification {
      box-shadow: 0 toRem(3) toRem(6) rgba($black, .16);
      background-color: $white;
      padding: toRem(24);

      .icon-warning {
        top: toRem(22);
        left: toRem(24);
      }
    }

    &.is-notification {
      &.state-warning {
        background-color: $color-alert-warning-background;
        border: 1px solid $color-status-2;
      }

      &.state-error {
        background-color: $danger-2;
        border: 1px solid $danger-1;
      }

      &.state-success {
        background-color: $color-alert-success-background;
        border: 1px solid $color-status-1;
      }
    }
  }

</style>
