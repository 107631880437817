import httpClient from '@/api/httpClient'

export const token = (data: any): Promise<any> => {
  return httpClient.post(
    '/api/v1/auth/token',
    data
  )
}

export const expire = (): Promise<any> => {
  return httpClient.post(
    '/api/v1/auth/expire',
    {}
  )
}

export const passwordlessStart = (data: any): Promise<any> => {
  return httpClient.post(
    '/api/v1/auth/passwordless/start',
    data
  )
}

export const passwordlessLogin = (data: any): Promise<any> => {
  return httpClient.post(
    '/api/v1/auth/passwordless',
    data
  )
}
