import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import nonAuthAutoLoginBeforeEnterGuard from '@/router/nonAuthAutoLoginBeforeEnterGuard'
import beforeEachGuard from '@/router/beforeEachGuard'
import afterEachGuard from '@/router/afterEachGuard'
import verifyRequiredScope from '@/router/verifyRequiredScope'
import Login from '@/views/public/Login.vue'
import BasePublic from '@/views/public/Base.vue'
import BaseAuthenticated from '@/views/authenticated/Base.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: BaseAuthenticated,
    children: [
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/authenticated/SettingsUsers.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Users'
        }
      },
      {
        path: 'users/create',
        name: 'UsersCreate',
        component: () => import('@/views/authenticated/SettingsUser.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Add user · Users'
        }
      },
      {
        path: 'users/edit/:id',
        name: 'UsersEdit',
        component: () => import('@/views/authenticated/SettingsUser.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Edit user · Users'
        }
      },
      {
        path: 'admins',
        name: 'Admins',
        component: () => import('@/views/authenticated/SettingsUsers.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Admins'
        }
      },
      {
        path: 'admins/create',
        name: 'AdminsCreate',
        component: () => import('@/views/authenticated/SettingsUser.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Add user · Admins'
        }
      },
      {
        path: 'admins/edit/:id',
        name: 'AdminsEdit',
        component: () => import('@/views/authenticated/SettingsUser.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Edit user · Admins'
        }
      },
      {
        path: 'applications',
        name: 'Applications',
        component: () => import('@/views/authenticated/SettingsApplications.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Applications'
        }
      },
      {
        path: 'applications/create',
        name: 'ApplicationsCreate',
        component: () => import('@/views/authenticated/SettingsApplication.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
              ? next()
              : next({
                name: 'Logout'
              })
          )
        },
        meta: {
          title: 'Add application · Applications'
        }
      },
      {
        path: 'applications/edit/:id',
        name: 'ApplicationsEdit',
        component: () => import('@/views/authenticated/SettingsApplication.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
                  ? next()
                  : next({
                    name: 'Logout'
                  })
          )
        },
        meta: {
          title: 'Edit application · Applications'
        }
      },
      {
        path: 'applications/files/:id',
        name: 'ApplicationsFiles',
        component: () => import('@/views/authenticated/SettingsApplicationFiles.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
                  ? next()
                  : next({
                    name: 'Logout'
                  })
          )
        },
        meta: {
          title: 'Application Files'
        }
      },
      {
        path: 'applications/files/upload/:id',
        name: 'ApplicationsFileUpload',
        component: () => import('@/views/authenticated/SettingsApplicationsFileUpload.vue'),
        beforeEnter: async (to, from, next) => {
          return (await verifyRequiredScope('users:update')
                  ? next()
                  : next({
                    name: 'Logout'
                  })
          )
        },
        meta: {
          title: 'Applications File Upload'
        }
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/authenticated/Logout.vue')
      }
    ]
  },
  {
    path: '/',
    component: BasePublic,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: nonAuthAutoLoginBeforeEnterGuard,
        meta: {
          title: 'Login'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: {
      name: 'Login'
    }
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  scrollBehavior () {
    return { top: 0 }
  },
  routes
})

router.beforeEach(beforeEachGuard)

router.afterEach(afterEachGuard)

export default router
