import axios from 'axios'
import store from '@/store'

// Note: Only to be used on non auth routes
// where you want to check if a user is logged in and redirect them into the app
// e.g on the login page

let axiosRetry = false

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  async response => {
    axiosRetry = false
    return response
  },
  async error => {
    const { status } = error.response
    const originalRequest = error.config
    if (status === 401 && !axiosRetry) {
      console.log(status, axiosRetry)
      // Refresh access token and retry original request
      axiosRetry = true
      try {
        const response = await authToken({
          grant_type: 'refresh_token'
        })
        if (response && response.data) {
          store.commit('auth/setAccessTokenExpiry', response.data.expires_in)
        }
      } catch (e) {
        return Promise.reject(error)
      }
      return axiosInstance(originalRequest)
    }
    return Promise.reject(error)
  }
)

export const getAuthenticatedUser = (): Promise<any> => {
  return axiosInstance.get('/api/v1/user')
}

const authToken = (data: any): Promise<any> => {
  return axiosInstance.post(
    '/api/v1/auth/token',
    data
  )
}
