import store from '@/store'
import { userNonAuthCheckAuthenticated } from '@/api'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  if (store.getters['user/user']) {
    return next({
      name: 'Applications'
    })
  } else {
    try {
      const response = await userNonAuthCheckAuthenticated.getAuthenticatedUser()
      if (response && response.data) {
        store.commit('user/setAuthenticatedUser', response.data)
        return next({
          name: 'Applications'
        })
      }
    } catch (e) {
      return next()
    }
  }
}
