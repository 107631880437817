import store from '@/store'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

const nonAuthRoutes = [
  'Login'
]

const logoutRoute = 'Logout'

export default async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  await store.dispatch('global/routerLoading', true)
  if (logoutRoute === to.name) {
    return next()
  }
  if (!store.getters['user/user'] && !nonAuthRoutes.includes(<string>to.name)) {
    await store.dispatch('user/getAuthenticatedUser')
  }
  return next()
}
