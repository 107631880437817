import httpClient from '@/api/httpClient'

export const getApplications = (): Promise<any> => {
  return httpClient.get('/api/v1/applications')
}

export const getApplication = (id: string): Promise<any> => {
  return httpClient.get(`/api/v1/applications/${id}`)
}

export const createApplication = (data: any): Promise<any> => {
  return httpClient.post('/api/v1/applications', data)
}

export const updateApplication = (id: string, data: any): Promise<any> => {
  return httpClient.put(`/api/v1/applications/${id}`, data)
}

export const deleteApplication = (id: string): Promise<any> => {
  return httpClient.delete(`/api/v1/applications/${id}`)
}

export const getApplicationFiles = (id: string): Promise<any> => {
  return httpClient.get(`/api/v1/applications/${id}/files`)
}

export const getApplicationFile = (id: string, fileId: string): Promise<any> => {
  return httpClient.get(`/api/v1/applications/${id}/files/${fileId}`)
}

export const getApplicationFileUploadUrl = (id: string, data: any): Promise<any> => {
  return httpClient.post(`/api/v1/applications/${id}/files/signed-upload`, data)
}

export const deleteApplicationFile = (id: string, fileId: string): Promise<any> => {
  return httpClient.delete(`/api/v1/applications/${id}/files/${fileId}`)
}
