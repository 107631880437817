import { Commit, Dispatch } from 'vuex'
import { users } from '@/api'

interface State {
  users: Array<any>
  usersCount: number
}

const state = {
  users: [],
  usersCount: 0
} as State

const getters = {
  users: (state: State): Array<any> => {
    return state.users
  }
}

const actions = {
  async getUsers ({ commit }: { commit: Commit }, params: any): Promise<void> {
    const response = await users.getUsers(params)
    if (response && response.data) {
      return response.data
    }
  },
  async getUser ({ commit }: { commit: Commit }, id: string): Promise<void> {
    const { data } = await users.getUser(id)
    if (data) {
      return data
    }
  },
  async createUser ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await users.createUser(d)
    return data
  },
  async updateUser ({ commit, dispatch, rootGetters }: { commit: Commit, dispatch: Dispatch, rootGetters: any }, d: any): Promise<void> {
    const userId = d.id
    const { data } = await users.updateUser(userId, d.data)
    console.log('updateUserResponse', data)
    return data
  },
  async deleteUser ({ commit }: { commit: Commit }, id: string): Promise<void> {
    const { data } = await users.deleteUser(id)
    return data
  },
  async createUserApplicationAssignment ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await users.createUserApplicationAssignment(d.id, d.appId)
    return data
  },
  async deleteUserApplicationAssignment ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, d: any): Promise<void> {
    const { data } = await users.deleteUserApplicationAssignment(d.id, d.appId)
    return data
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters
}
