import { createStore } from 'vuex'
import global from '@/store/modules/global'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import users from '@/store/modules/users'
import applications from '@/store/modules/applications'

export default createStore({
  modules: {
    global,
    auth,
    user,
    users,
    applications
  },
  strict: process.env.NODE_ENV !== 'production'
})
