import { Commit } from 'vuex'
import { base } from '@/api'

interface State {
  httpClientLoading: boolean
  routerLoading: boolean
  routerLoadingLastShow: number
  routerLoadingLastHide: number
  windowWidth: number
  windowHeight: number
}

const state = {
  httpClientLoading: false,
  routerLoading: true,
  routerLoadingLastShow: 0,
  routerLoadingLastHide: Date.now(),
  windowWidth: false as number | boolean,
  windowHeight: false as number | boolean
} as State

const getters = {
  httpClientLoading: (state: State): string | boolean => {
    return state.httpClientLoading
  },
  windowWidth: (state: State): number => {
    return state.windowWidth
  },
  windowHeight: (state: State): number => {
    return state.windowHeight
  }
}

const routerLoaderShowDelay = 500

const actions = {
  httpClientLoading ({ commit }: { commit: Commit }, loading: boolean): void {
    commit('setHttpClientLoading', loading)
  },
  routerLoading ({ commit, state }: { commit: Commit, state: State }, loading: boolean): void {
    if (loading) {
      console.log('SHOW LOADER')
      commit('setRouterLoadingLastShow')
      setTimeout(() => {
        const timeBetweenShowAndHide = state.routerLoadingLastShow - state.routerLoadingLastHide
        console.log('timeBetweenShowAndHide', timeBetweenShowAndHide)
        if (timeBetweenShowAndHide >= routerLoaderShowDelay) {
          commit('setRouterLoading', loading)
        }
      }, routerLoaderShowDelay)
    } else {
      console.log('HIDE LOADER')
      commit('setRouterLoadingLastHide')
      commit('setRouterLoading', loading)
    }
  },
  async getCsrfToken ({ commit }: { commit: Commit }): Promise<void> {
    try {
      await base.getCsrfToken()
      commit('setCsrfToken')
    } catch (e) {
      console.log(e)
    }
  }
}

const mutations = {
  setHttpClientLoading (state: State, loading: boolean): void {
    state.httpClientLoading = loading
  },
  setRouterLoading (state: State, loading: boolean): void {
    state.routerLoading = loading
  },
  setRouterLoadingLastShow (state: State): void {
    console.log('UPDATE - setRouterLoadingLastShow')
    state.routerLoadingLastShow = Date.now()
  },
  setRouterLoadingLastHide (state: State): void {
    console.log('UPDATE - setRouterLoadingLastHide')
    state.routerLoadingLastHide = Date.now()
  },
  setWindowWidth (state: State, width: number): void {
    state.windowWidth = width
  },
  setWindowHeight (state: State, width: number): void {
    state.windowHeight = width
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
