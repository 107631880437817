import { Commit } from 'vuex'
import { user } from '@/api'

interface State {
  user: boolean | any
}

const state: State = {
  user: false
}

// TODO: Set user interface
const getters = {
  user: (state: State): any => {
    return state.user
  }
}

const actions = {
  async getAuthenticatedUser ({ commit }: { commit: Commit }): Promise<void> {
    const response = await user.getAuthenticatedUser()
    if (response && response.data) {
      commit('setAuthenticatedUser', response.data)
    }
  },
  async expireUser ({ commit }: { commit: Commit }): Promise<void> {
    commit('setAuthenticatedUser', false)
  }
}

const mutations = {
  setAuthenticatedUser (state: State, user: any): void {
    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
