import { Commit, Dispatch } from 'vuex'
import { auth } from '@/api'

interface State {
  accessTokenExpiry: number
  refreshingToken: boolean
}

const state: State = {
  accessTokenExpiry: 0,
  refreshingToken: false
}

const actions = {
  async passwordlessStart ({ commit, dispatch, rootGetters }: { commit: Commit, dispatch: Dispatch, rootGetters: any }, d: any): Promise<any> {
    const { data } = await auth.passwordlessStart(d)
    return data
  },
  async passwordlessLogin ({ commit, dispatch, rootGetters }: { commit: Commit, dispatch: Dispatch, rootGetters: any }, d: any): Promise<any> {
    d = {
      ...d
    }
    const { data } = await auth.passwordlessLogin(d)
    if (data) {
      commit('setAccessTokenExpiry', data.expires_in)
      await dispatch('user/getAuthenticatedUser', null, { root: true })
      return true
    }
  },
  async refreshTokenGrant ({ commit }: { commit: Commit, rootGetters: any }): Promise<void> {
    commit('setRefreshingToken', true)
    const response = await auth.token({
      grant_type: 'refresh_token'
    })
    if (response && response.data) {
      commit('setAccessTokenExpiry', response.data.expires_in)
    }
    commit('setRefreshingToken', false)
  },
  async expire ({ dispatch }: { dispatch: Dispatch }): Promise<void> {
    await auth.expire()
    await dispatch('user/expireUser', null, { root: true })
  }
}

const mutations = {
  setAccessTokenExpiry (state: State, expiresIn: number): void {
    // console.log(Date.now())
    state.accessTokenExpiry = Date.now() + (expiresIn * 1000)
  },
  setRefreshingToken (state: State, refreshing: boolean): void {
    state.refreshingToken = refreshing
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
